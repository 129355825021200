<script setup>
import {RouterView, useRoute, useRouter} from 'vue-router'
import TopBar from "@/components/TopBar.vue";
import Footer from "@/components/Footer.vue";
import LoadingSpinner from "./components/LoadingSpinner.vue";
import {useAppStore} from "./stores/app";
import {storeToRefs} from "pinia";
import {computed, onMounted, ref, watch} from "vue";
import {useDisplay} from "vuetify";
import {EndpointsFactory} from "@/utilities/endpoints";
import {useI18n} from "vue-i18n";
import {useHead} from "@unhead/vue";
import {URLS_FACTORY} from "./router/urls.js";
import {useDomStore} from "./stores/dom.js";

const appStore = useAppStore();
const {loading, domLoading, errorData, error, saveOkFeedback, expiredLogout} = storeToRefs(appStore);
const $route = useRoute();
const $router = useRouter();
const domStore = useDomStore();
const {t: $t, locale} = useI18n();

const metas = [{
  name: 'description',
  content: import.meta.env.VITE_META_SITE_DESCRIPTION
}];
if(!(import.meta.env.PROD && import.meta.env.MODE === 'prod')){
  metas.push({
    meta: 'robots',
    content: 'none'
  });
}

useHead({
  title: import.meta.env.VITE_META_SITE_TITLE,
  meta: metas
});

const isDashboardAdmin = ref(false);
const isDashboardUser = ref(false);
const {mobile} = useDisplay();
watch($route, to => {
  if(to.path.startsWith('/dashboard/admin')){
    isDashboardAdmin.value = true;
    return;
  }
  isDashboardAdmin.value = false;
  if(to.path.startsWith('/dashboard')){
    isDashboardUser.value = true;
    return;
  }
  isDashboardUser.value = false;
});

const errorBody = computed(() => errorData.value.body ? errorData.value.body : $t('global.generic_error'));

onMounted(async () => {
  EndpointsFactory.initWorker(new Worker(new URL('@/FetchWebWorker.js', import.meta.url), {type: 'module'}));
  await domStore.loadDomData(null, locale.value, false, true);
  appStore.hideSpinner();
});

const isMacPC = () => navigator.userAgent.indexOf('Mac OS X') !== -1;

function okError(){
  if(expiredLogout.value){
    expiredLogout.value = false;
    $router.push(URLS_FACTORY.URLS_PATHS.ROOT).then();
  }
  appStore.hideError();
}
</script>

<template>
  <v-layout full-height>
    <loading-spinner v-show="loading || domLoading"></loading-spinner>
    <div class="wrapper-save" v-if="saveOkFeedback">
      <div class="box-save">
        <i class="mdi mdi-check-bold"></i>
        <div>{{ $t('global.saved') }}</div>
      </div>
    </div>
    <v-app
        :class="{'app-dash':isDashboardUser,'app-admin-dash':isDashboardAdmin, 'mobile-app':mobile, 'is-safari' : isMacPC()}">
      <top-bar :class="{'top-admin-dash': isDashboardAdmin }"></top-bar>
      <v-main :class="{'mobile-main':mobile }">
        <router-view/>

        <!-- dialog di errore -->
        <v-dialog v-model="error" class="dialog-primary" width="auto">
          <v-card>
            <v-card-title>{{ errorData.title }}</v-card-title>

            <v-card-text><span v-html="errorBody"></span></v-card-text>

            <v-card-actions class="gen-wrapper-btn">
              <v-btn class="m-btn" @click="okError">{{ errorData.okBtn ? errorData.okBtn : $t('global.ok') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-main>
      <Footer></Footer>
    </v-app>
  </v-layout>
</template>

<style lang="less" scoped>
header {
  line-height: 1.5;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

//@media (min-width: 1024px) {
//  header {
//    display: flex;
//    place-items: center;
//    padding-right: calc(var(--section-gap) / 2);
//  }
//
//  .logo {
//    margin: 0 2rem 0 0;
//  }
//
//  header .wrapper {
//    display: flex;
//    place-items: flex-start;
//    flex-wrap: wrap;
//  }
//
//  nav {
//    text-align: left;
//    margin-left: -1rem;
//    font-size: 1rem;
//
//    padding: 1rem 0;
//    margin-top: 1rem;
//  }
//}

//stile del box che avverte il corretto salvataggio
//dovrebbe comparire e poi andar via in poco tempo
.wrapper-save {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 910;

  .box-save {
    .set-border-radius(@border-radius-box);
    background: @primary-color;
    width: 250px;
    padding: 30px;
    font-size: 25px;
    font-family: 'AktivGroteskEx', Serif;
    color: @font-color;
    text-align: center;
    opacity: 0.8;
    animation: zoom-in-zoom-out 0.7s;

    i {
      display: block;
      font-size: 40px;
      animation: fadeInAnimation 0.5s;
      animation-iteration-count: 1;
    }
  }
}

//animazioni box salvato
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1.2, 1.2);
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: scale(1, 1) rotate(60deg);
  }
  100% {
    opacity: 1;
    transform: scale(1.2, 1.2) rotate(0deg);
  }
}
</style>