import {computed, reactive} from 'vue';
import {defineStore} from 'pinia';
import {useAppStore} from "@/stores/app";
import {WorkerResponseCode} from "../utilities/workerUtilites.js";
import {EndpointsFactory} from "../utilities/endpoints.js";
import {PROPERTY_REGULATION_VALUE_TYPES} from "@/utilities/enums.js";

export const useDomStore = defineStore('dom', () => {
	const serverData = reactive({
		serverCities: [],
		serverPropertyCategoryLabels: {},
		serverPropertyCategories: [],
		serverPropertyAssignLabels: {},
		serverPropertyAssigns: [],
		serverCountryLabels: {},
		serverCountries: [],
		serverPropertyTypologies: [],
		serverPropertyAllTypologies: [],
		serverPropertyTypologiesByCategory: {},
		serverPropertyTypologyLabels: {},
		serverDirectionLabels: {},
		serverDirections: [],
		serverMeasurementLabels: {},
		serverMeasurements: [],
		serverHeatingTypeLabels: {},
		serverHeatingTypes: [],
		serverHeatingPowerLabels: {},
		serverHeatingPowers: [],
		serverPropertyConditionLabels: {},
		serverPropertyConditions: [],
		serverPropertyPurposeLabels: {},
		serverPropertyPurposes: [],
		serverPropertyRegulationLabels: {},
		serverPropertyRegulationSublabelLabels: {},
		serverPropertyRegulations: [],
		serverPropertyRegulationOptionsLabelsByRegulation: {},
		serverPropertyRegulationOptionsByRegulation: {},
		serverPropertyOutdoorLabels: {},
		serverPropertyOutdoors: [],
		serverPropertyBenefitsLabels: {},
		serverPropertyBenefits: [],
		serverPropertyBenefitCategoriesLabels: {},
		serverPropertyBenefitsByCategory: [],
		serverActivityLabels: {},
		serverActivities: [],
		serverRequestBenefitLabels: {},
		serverRequestBenefits: [],
		serverTimeframeLabels: {},
		serverTimeframes: [],
		serverTimeframesFilterLabels: {},
		serverTimeframesFilters: [],
		serverNewsStatusesLabels: {},
		serverNewsStatuses: [],
		serverTagLabels: {},
		serverTags: []
	});

	const cities = computed(() => {
		return serverData.serverCities.filter(c => c[DOM_CITIES_FIELDS.ENABLED]).map(c => {
			return {value: c[DOM_CITIES_FIELDS.ID], title: c[DOM_CITIES_FIELDS.NAME]};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const propertyCategories = computed(() => {
		return serverData.serverPropertyCategories.map(c => {
			return {
				value: c[DOM_PROPERTY_CATEGORIES_FIELDS.ID],
				title: serverData.serverPropertyCategoryLabels[c[DOM_PROPERTY_CATEGORIES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const propertyAssigns = computed(() => {
		return serverData.serverPropertyAssigns.map(c => {
			return {
				value: c[DOM_PROPERTY_ASSIGNS_FIELDS.ID],
				title: serverData.serverPropertyAssignLabels[c[DOM_PROPERTY_ASSIGNS_FIELDS.LBL_KEY]]
			};
		});
	});

	const countries = computed(() => {
		return serverData.serverCountries.map(c => {
			return {
				value: c[DOM_COUNTRIES_FIELDS.ID],
				title: serverData.serverCountryLabels[c[DOM_COUNTRIES_FIELDS.NAME]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const propertyTypologies = computed(() => {
		return serverData.serverPropertyTypologies.map(c => {
			return {
				value: c[DOM_PROPERTY_TYPOLOGIES_FIELDS.ID],
				title: serverData.serverPropertyTypologyLabels[c[DOM_PROPERTY_TYPOLOGIES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const propertyAllTypologies = computed(() => {
		return serverData.serverPropertyAllTypologies.map(c => {
			return {
				value: c[DOM_PROPERTY_TYPOLOGIES_FIELDS.ID],
				title: serverData.serverPropertyTypologyLabels[c[DOM_PROPERTY_TYPOLOGIES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const directions = computed(() => {
		return serverData.serverDirections.map(c => {
			return {
				value: c[DOM_DIRECTIONS_FIELDS.ID],
				title: serverData.serverDirectionLabels[c[DOM_DIRECTIONS_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const measurements = computed(() => {
		return serverData.serverMeasurements.map(c => {
			return {
				value: c[DOM_MEASUREMENTS_FIELDS.ID],
				title: serverData.serverMeasurementLabels[c[DOM_MEASUREMENTS_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const heatingTypes = computed(() => {
		return serverData.serverHeatingTypes.map(c => {
			return {
				value: c[DOM_HEATING_TYPES_FIELDS.ID],
				title: serverData.serverHeatingTypeLabels[c[DOM_HEATING_TYPES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const heatingPowers = computed(() => {
		return serverData.serverHeatingPowers.map(c => {
			return {
				value: c[DOM_HEATING_POWERS_FIELDS.ID],
				title: serverData.serverHeatingPowerLabels[c[DOM_HEATING_POWERS_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const propertyConditions = computed(() => {
		return serverData.serverPropertyConditions.map(c => {
			return {
				value: c[DOM_PROPERTY_CONDITIONS_FIELDS.ID],
				title: serverData.serverPropertyConditionLabels[c[DOM_PROPERTY_CONDITIONS_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const propertyPurposes = computed(() => {
		return serverData.serverPropertyPurposes.map(c => {
			return {
				value: c[DOM_PROPERTY_PURPOSES_FIELDS.ID],
				title: serverData.serverPropertyPurposeLabels[c[DOM_PROPERTY_PURPOSES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const propertyAppurtenances = computed(() => {
		if(!serverData.serverPropertyTypologiesByCategory[import.meta.env.VITE_APPURTENANCE_PROPERTY_CATEGORY_ID]){
			return [];
		}
		return serverData.serverPropertyTypologiesByCategory[import.meta.env.VITE_APPURTENANCE_PROPERTY_CATEGORY_ID].map(c => {
			return {
				value: c[DOM_PROPERTY_TYPOLOGIES_FIELDS.ID],
				title: serverData.serverPropertyTypologyLabels[c[DOM_PROPERTY_TYPOLOGIES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const propertyRegulations = computed(() => {
		return serverData.serverPropertyRegulations.map(c => {
			const res = {
				value: c[DOM_PROPERTY_REGULATIONS_FIELDS.ID],
				title: serverData.serverPropertyRegulationLabels[c[DOM_PROPERTY_REGULATIONS_FIELDS.LBL_KEY]],
				required: c[DOM_PROPERTY_REGULATIONS_FIELDS.REQUIRED],
				type: c[DOM_PROPERTY_REGULATIONS_FIELDS.VALUE_TYPE]
			};
			if(c[DOM_PROPERTY_REGULATIONS_FIELDS.SUB_LABEL]){
				res.subLabel = serverData.serverPropertyRegulationSublabelLabels[c[DOM_PROPERTY_REGULATIONS_FIELDS.SUB_LABEL][DOM_PROPERTY_REGULATIONS_FIELDS.SUB_LABEL__LABEL_KEY]];
			}
			if(c[DOM_PROPERTY_REGULATIONS_FIELDS.VALUE_TYPE] === PROPERTY_REGULATION_VALUE_TYPES.SELECT){
				res.options = serverData.serverPropertyRegulationOptionsByRegulation[c[DOM_PROPERTY_REGULATIONS_FIELDS.ENUM_DOM_ROUTE]].map(o => {
					return {
						value: o[DOM_PROPERTY_REGULATIONS_FIELDS.ID],
						title: serverData.serverPropertyRegulationOptionsLabelsByRegulation[c[DOM_PROPERTY_REGULATIONS_FIELDS.ENUM_DOM_ROUTE]][o[DOM_PROPERTY_REGULATIONS_FIELDS.LBL_KEY + '_' + c[DOM_PROPERTY_REGULATIONS_FIELDS.ENUM_DOM_ROUTE]]]
					};
				}).sort((c1, c2) => {
					return c1.title ? c1.title.localeCompare(c2.title) : c2;
				});
			}
			return res;
		});
	});

	const propertyOutdoors = computed(() => {
		return serverData.serverPropertyOutdoors.map(c => {
			return {
				value: c[DOM_PROPERTY_OUTDOORS_FIELDS.ID],
				title: serverData.serverPropertyOutdoorLabels[c[DOM_PROPERTY_OUTDOORS_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const propertyBenefits = computed(() => {
		return serverData.serverPropertyBenefits.map(c => {
			return {
				value: c[DOM_PROPERTY_BENEFIT_CATEGORY_FIELDS.ID],
				title: serverData.serverPropertyBenefitCategoriesLabels[c[DOM_PROPERTY_BENEFIT_CATEGORY_FIELDS.LBL_KEY]],
				options: serverData.serverPropertyBenefitsByCategory[c[DOM_PROPERTY_BENEFIT_CATEGORY_FIELDS.ID]].map(o => {
					return {
						value: o[DOM_PROPERTY_BENEFIT_FIELDS.ID],
						title: serverData.serverPropertyBenefitsLabels[o[DOM_PROPERTY_BENEFIT_FIELDS.LBL_KEY]]
					};
				})
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const activities = computed(() => {
		return serverData.serverActivities.map(c => {
			return {
				value: c[DOM_ACTIVITIES_FIELDS.ID],
				title: serverData.serverActivityLabels[c[DOM_ACTIVITIES_FIELDS.LBL_KEY]],
				icon: c[DOM_ACTIVITIES_FIELDS.ICON]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const requestBenefits = computed(() => {
		return serverData.serverRequestBenefits.map(c => {
			return {
				value: c[DOM_REQUEST_BENEFITS_FIELDS.ID],
				title: serverData.serverRequestBenefitLabels[c[DOM_REQUEST_BENEFITS_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const timeframes = computed(() => {
		return serverData.serverTimeframes.map(c => {
			return {
				value: c[DOM_TIMEFRAMES_FIELDS.ID],
				title: serverData.serverTimeframeLabels[c[DOM_TIMEFRAMES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const timeframesFilters = computed(() => {
		return serverData.serverTimeframesFilters.map(c => {
			return {
				value: c[DOM_TIMEFRAMES_FILTERS_FIELDS.ID],
				title: serverData.serverTimeframesFilterLabels[c[DOM_TIMEFRAMES_FILTERS_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const newsStatuses = computed(() => {
		return serverData.serverNewsStatuses.map(c => {
			return {
				value: c[DOM_NEWS_STATUSES_FIELDS.ID],
				title: serverData.serverNewsStatusesLabels[c[DOM_NEWS_STATUSES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	const tags = computed(() => {
		return serverData.serverTags.map(c => {
			return {
				value: c[DOM_TAGS_FIELDS.ID],
				title: serverData.serverTagLabels[c[DOM_TAGS_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	function reset(){
		serverData.serverCities = [];
		serverData.serverPropertyCategoryLabels = {};
		serverData.serverPropertyCategories = [];
		serverData.serverPropertyAssignLabels = {};
		serverData.serverPropertyAssigns = [];
		serverData.serverCountryLabels = {};
		serverData.serverCountries = [];
		serverData.serverPropertyAllTypologies = [];
		serverData.serverPropertyTypologiesByCategory = {};
		serverData.serverPropertyTypologyLabels = {};
		serverData.serverPropertyTypologies = [];
		serverData.serverDirectionLabels = {};
		serverData.serverDirections = [];
		serverData.serverMeasurementLabels = {};
		serverData.serverMeasurements = [];
		serverData.serverHeatingTypeLabels = {};
		serverData.serverHeatingTypes = [];
		serverData.serverHeatingPowerLabels = {};
		serverData.serverHeatingPowers = [];
		serverData.serverPropertyConditionLabels = {};
		serverData.serverPropertyConditions = [];
		serverData.serverPropertyPurposeLabels = {};
		serverData.serverPropertyPurposes = [];
		serverData.serverPropertyRegulationLabels = {};
		serverData.serverPropertyRegulationSublabelLabels = {};
		serverData.serverPropertyRegulations = [];
		serverData.serverPropertyRegulationOptionsLabelsByRegulation = {};
		serverData.serverPropertyRegulationOptionsByRegulation = {};
		serverData.serverPropertyOutdoorLabels = {};
		serverData.serverPropertyOutdoors = [];
		serverData.serverPropertyBenefitsLabels = {};
		serverData.serverPropertyBenefits = [];
		serverData.serverPropertyBenefitCategoriesLabels = {};
		serverData.serverPropertyBenefitsByCategory = [];
		serverData.serverActivityLabels = {};
		serverData.serverActivities = [];
		serverData.serverRequestBenefitLabels = {};
		serverData.serverRequestBenefits = [];
		serverData.serverTimeframeLabels = {};
		serverData.serverTimeframes = [];
		serverData.serverTimeframesFilterLabels = {};
		serverData.serverTimeframesFilters = [];
		serverData.serverNewsStatusesLabels = {};
		serverData.serverNewsStatuses = [];
		serverData.serverTagLabels = {};
		serverData.serverTags = [];
	}

	function __fetchEntity(token, serverDataEntity, endpoint, responseCode){
		return new Promise((resolve, reject) => {
			if(serverData[serverDataEntity] && serverData[serverDataEntity].length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(endpoint),
					token: token,
					method: 'GET'
				},
				responseCode, ({result}) => {
					serverData[serverDataEntity] = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function __fetchEntityLabels(lang, token, serverLabelsEntity, labelPrefix, response){
		return new Promise((resolve, reject) => {
			if(serverData[serverLabelsEntity] && Object.keys(serverData[serverLabelsEntity]).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: lang,
					labels_prefix: labelPrefix
				}),
				token: token,
				method: 'GET'
			}, response, ({result}) => {
				serverData[serverLabelsEntity] = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchCities(currentToken){
		return __fetchEntity(currentToken, "serverCities", EndpointsFactory.ENDPOINTS.DOM_GET_CITIES, WorkerResponseCode.DOM_GET_CITIES);
	}

	function _fetchPropertiesCategoryLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverPropertyCategoryLabels", "dom_ta_property_categories", WorkerResponseCode.LABELS_GET_PROPERTY_CATEGORIES);
	}

	function _fetchPropertyCategories(currentToken){
		return __fetchEntity(currentToken, "serverPropertyCategories", EndpointsFactory.ENDPOINTS.DOM_GET_PROPERTY_CATEGORIES, WorkerResponseCode.DOM_GET_PROPERTY_CATEGORIES);
	}

	function _fetchPropertiesAssignLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverPropertyAssignLabels", "dom_ta_property_assign", WorkerResponseCode.LABELS_GET_PROPERTY_ASSIGNS);
	}

	function _fetchPropertyAssigns(currentToken){
		return __fetchEntity(currentToken, "serverPropertyAssigns", EndpointsFactory.ENDPOINTS.DOM_GET_PROPERTY_ASSIGNS, WorkerResponseCode.DOM_GET_PROPERTY_ASSIGNS);
	}

	function _fetchCountriesLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverCountryLabels", "dom_ta_countries", WorkerResponseCode.LABELS_GET_COUNTRIES);
	}

	function _fetchCountries(currentToken){
		return __fetchEntity(currentToken, "serverCountries", EndpointsFactory.ENDPOINTS.DOM_GET_COUNTRIES, WorkerResponseCode.DOM_GET_COUNTRIES);
	}

	function _fetchPropertiesTypologyLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverPropertyTypologyLabels", "dom_ta_property_typologies", WorkerResponseCode.LABELS_GET_PROPERTY_TYPOLOGIES);
	}

	function _fetchPropertyAllTypologies(currentToken){
		return __fetchEntity(currentToken, "serverPropertyAllTypologies", EndpointsFactory.ENDPOINTS.DOM_GET_PROPERTY_TYPOLOGIES, WorkerResponseCode.DOM_GET_PROPERTY_TYPOLOGIES);
	}

	//questa è speciale perché sono dipendenti dalla property category
	function fetchPropertyTypologies(currentToken, property_category_id){
		if(!property_category_id){
			return Promise.resolve();
		}
		return new Promise((resolve, reject) => {
			const typologiesByCategory = serverData.serverPropertyTypologiesByCategory[property_category_id];
			if(typologiesByCategory && typologiesByCategory.length){ //aggiorno solo se non le ho
				serverData.serverPropertyTypologies = serverData.serverPropertyTypologiesByCategory[property_category_id];
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.DOM_GET_PROPERTY_TYPOLOGIES, {property_category_id: property_category_id}),
				token: currentToken,
				method: 'GET'
			},
				WorkerResponseCode.DOM_GET_PROPERTY_ASSIGNS + property_category_id, ({result}) => {
					serverData.serverPropertyTypologiesByCategory[property_category_id] = serverData.serverPropertyTypologies = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchDirectionLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverDirectionLabels", "dom_ta_directions", WorkerResponseCode.LABELS_GET_DIRECTIONS);
	}

	function _fetchDirections(currentToken){
		return __fetchEntity(currentToken, "serverDirections", EndpointsFactory.ENDPOINTS.DOM_GET_DIRECTIONS, WorkerResponseCode.DOM_GET_DIRECTIONS);
	}

	function _fetchMeasurementLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverMeasurementLabels", "dom_ta_measurement_units", WorkerResponseCode.LABELS_GET_MEASUREMENTS);
	}

	function _fetchMeasurements(currentToken){
		return __fetchEntity(currentToken, "serverMeasurements", EndpointsFactory.ENDPOINTS.DOM_GET_MEASUREMENTS, WorkerResponseCode.DOM_GET_MEASUREMENTS);
	}

	function _fetchHeatingTypeLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverHeatingTypeLabels", "dom_ta_heating_types", WorkerResponseCode.LABELS_GET_HEATING_TYPES);
	}

	function _fetchHeatingTypes(currentToken){
		return __fetchEntity(currentToken, "serverHeatingTypes", EndpointsFactory.ENDPOINTS.DOM_GET_HEATING_TYPES, WorkerResponseCode.DOM_GET_HEATING_TYPES);
	}

	function _fetchHeatingPowerLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverHeatingPowerLabels", "dom_ta_heating_powers", WorkerResponseCode.LABELS_GET_HEATING_POWERS);
	}

	function _fetchHeatingPowers(currentToken){
		return __fetchEntity(currentToken, "serverHeatingPowers", EndpointsFactory.ENDPOINTS.DOM_GET_HEATING_POWERS, WorkerResponseCode.DOM_GET_HEATING_POWERS);
	}

	function _fetchPropertiesConditionLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverPropertyConditionLabels", "dom_ta_property_conditions", WorkerResponseCode.LABELS_GET_PROPERTY_CONDITIONS);
	}

	function _fetchPropertyConditions(currentToken){
		return __fetchEntity(currentToken, "serverPropertyConditions", EndpointsFactory.ENDPOINTS.DOM_GET_PROPERTY_CONDITIONS, WorkerResponseCode.DOM_GET_PROPERTY_CONDITIONS);
	}

	function _fetchPropertiesPurposesLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverPropertyPurposeLabels", "dom_ta_property_purposes", WorkerResponseCode.LABELS_GET_PROPERTY_PURPOSES);
	}

	function _fetchPropertyPurposes(currentToken){
		return __fetchEntity(currentToken, "serverPropertyPurposes", EndpointsFactory.ENDPOINTS.DOM_GET_PROPERTY_PURPOSES, WorkerResponseCode.DOM_GET_PROPERTY_PURPOSES);
	}

	function _fetchPropertiesAppurtenances(currentToken){
		const app_cat_id = Number(import.meta.env.VITE_APPURTENANCE_PROPERTY_CATEGORY_ID);
		if(serverData.serverPropertyTypologiesByCategory[app_cat_id]){
			return Promise.resolve([]);
		}
		return fetchPropertyTypologies(currentToken, app_cat_id);
	}

	function _fetchPropertiesRegulationLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverPropertyRegulationLabels", "dom_ta_regulations", WorkerResponseCode.LABELS_GET_PROPERTY_REGULATIONS);
	}

	function _fetchPropertiesRegulationSublabelLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverPropertyRegulationSublabelLabels", "dom_ta_regulation_sublabels", WorkerResponseCode.LABELS_GET_PROPERTY_REGULATION_SUBLABELS);
	}

	function _fetchPropertyRegulations(currentToken, currentLang){
		return __fetchEntity(currentToken, "serverPropertyRegulations", EndpointsFactory.ENDPOINTS.DOM_GET_PROPERTY_REGULATIONS, WorkerResponseCode.DOM_GET_PROPERTY_REGULATIONS).then(() => {
			const promises = [];
			serverData.serverPropertyRegulations.filter(r => r[DOM_PROPERTY_REGULATIONS_FIELDS.VALUE_TYPE] === PROPERTY_REGULATION_VALUE_TYPES.SELECT).forEach(pr => {
				promises.push(_fetchPropertyRegulationOptions(currentToken, pr[DOM_PROPERTY_REGULATIONS_FIELDS.ENUM_DOM_ROUTE]));
				promises.push(_fetchPropertyRegulationOptionsLabels(currentLang, currentToken, pr[DOM_PROPERTY_REGULATIONS_FIELDS.ENUM_DOM_ROUTE]));
			});

			return Promise.all(promises);
		});
	}

	//questa è speciale perché sono dipendenti dalla property_regulation_name
	function _fetchPropertyRegulationOptions(currentToken, property_regulation_name){
		if(!property_regulation_name){
			return Promise.resolve([]);
		}
		return new Promise((resolve, reject) => {
			const optionsByRegulation = serverData.serverPropertyRegulationOptionsByRegulation[property_regulation_name];
			if(optionsByRegulation && optionsByRegulation.length){ //aggiorno solo se non le ho
				return resolve(optionsByRegulation);
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.DOM_GET_PROPERTY_REGULATION_OPTION, {regulation_root: property_regulation_name}),
				token: currentToken,
				method: 'GET'
			},
				WorkerResponseCode.DOM_GET_PROPERTY_REGULATION_OPTIONS + property_regulation_name, ({result}) => {
					serverData.serverPropertyRegulationOptionsByRegulation[property_regulation_name] = result;
					resolve(result)
				}, () => {
					reject();
				});
		});
	}

	function _fetchPropertyRegulationOptionsLabels(currentLang, currentToken, property_regulation_name){
		if(!property_regulation_name){
			return Promise.resolve([]);
		}
		return new Promise((resolve, reject) => {
			const optionsLabelsByRegulation = serverData.serverPropertyRegulationOptionsLabelsByRegulation[property_regulation_name];
			if(optionsLabelsByRegulation && optionsLabelsByRegulation.length){ //aggiorno solo se non le ho
				return resolve(optionsLabelsByRegulation);
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: 'dom_ta_regulation_' + property_regulation_name
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.LABELS_GET_PROPERTY_REGULATION_OPTIONS + property_regulation_name, ({result}) => {
				serverData.serverPropertyRegulationOptionsLabelsByRegulation[property_regulation_name] = result;
				resolve(result);
			}, () => {
				reject();
			});
		});
	}

	function _fetchPropertyOutdoorLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverPropertyOutdoorLabels", "dom_ta_property_outdoor_types", WorkerResponseCode.LABELS_GET_PROPERTY_OUTDOORS);
	}

	function _fetchPropertyOutdoors(currentToken){
		return __fetchEntity(currentToken, "serverPropertyOutdoors", EndpointsFactory.ENDPOINTS.DOM_GET_PROPERTY_OUTDOORS, WorkerResponseCode.DOM_GET_PROPERTY_OUTDOORS);
	}

	function _fetchPropertyBenefitCategoriesLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverPropertyBenefitCategoriesLabels", "dom_ta_benefit_categories", WorkerResponseCode.LABELS_GET_PROPERTY_BENEFIT_CATEGORIES);
	}

	function _fetchPropertyBenefitLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverPropertyBenefitsLabels", "dom_ta_benefits", WorkerResponseCode.LABELS_GET_PROPERTY_BENEFITS);
	}

	function _fetchPropertyBenefits(currentToken){
		return __fetchEntity(currentToken, "serverPropertyBenefits", EndpointsFactory.ENDPOINTS.DOM_GET_PROPERTY_BENEFITS, WorkerResponseCode.DOM_GET_PROPERTY_BENEFITS).then(() => {
			const promises = [];
			serverData.serverPropertyBenefits.forEach(benefit => {
				promises.push(_fetchPropertyBenefitsByCategory(currentToken, benefit[DOM_PROPERTY_BENEFIT_CATEGORY_FIELDS.ID]));
			});
			return Promise.all(promises);
		});
	}

	//questa è speciale perché sono dipendenti dalla benefit_category
	function _fetchPropertyBenefitsByCategory(currentToken, benefit_category){
		if(!benefit_category){
			return Promise.resolve([]);
		}
		return new Promise((resolve, reject) => {
			const benefitsByCategory = serverData.serverPropertyBenefitsByCategory[benefit_category];
			if(benefitsByCategory && benefitsByCategory.length){ //aggiorno solo se non le ho
				return resolve(benefitsByCategory);
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.DOM_GET_PROPERTY_BENEFITS_BY_CATEGORY, {benefit_category_id: benefit_category}),
				token: currentToken,
				method: 'GET'
			},
				WorkerResponseCode.DOM_GET_PROPERTY_BENEFITS_BY_CATEGORY + benefit_category, ({result}) => {
					serverData.serverPropertyBenefitsByCategory[benefit_category] = result;
					resolve(result)
				}, () => {
					reject();
				});
		});
	}

	function _fetchActivityLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverActivityLabels", "dom_ta_activity_types", WorkerResponseCode.LABELS_GET_ACTIVITIES);
	}

	function _fetchActivities(currentToken){
		return __fetchEntity(currentToken, "serverActivities", EndpointsFactory.ENDPOINTS.DOM_GET_ACTIVITIES, WorkerResponseCode.DOM_GET_ACTIVITIES);
	}

	function _fetchRequestBenefitLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverRequestBenefitLabels", "dom_ta_benefits", WorkerResponseCode.LABELS_GET_REQUEST_BENEFITS);
	}

	function _fetchRequestBenefits(currentToken){
		return __fetchEntity(currentToken, "serverRequestBenefits", EndpointsFactory.ENDPOINTS.DOM_GET_REQUEST_BENEFITS, WorkerResponseCode.DOM_GET_REQUEST_BENEFITS);
	}

	function _fetchTimeframeLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverTimeframeLabels", "dom_ta_timeframes", WorkerResponseCode.LABELS_GET_TIMEFRAMES);
	}

	function _fetchTimeframes(currentToken){
		return __fetchEntity(currentToken, "serverTimeframes", EndpointsFactory.ENDPOINTS.DOM_GET_TIMEFRAMES, WorkerResponseCode.DOM_GET_TIMEFRAMES);
	}

	function _fetchTimeframesFilterLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverTimeframesFilterLabels", "dom_ta_timeframe_filters", WorkerResponseCode.LABELS_GET_TIMEFRAMES_FILTERS);
	}

	function _fetchTimeframesFilters(currentToken){
		return __fetchEntity(currentToken, "serverTimeframesFilters", EndpointsFactory.ENDPOINTS.DOM_GET_TIMEFRAMES_FILTERS, WorkerResponseCode.DOM_GET_TIMEFRAMES_FILTERS);
	}

	function _fetchNewsStatusesLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverNewsStatusesLabels", "dom_ta_news_statuses", WorkerResponseCode.LABELS_GET_NEWS_STATUSES);
	}

	function _fetchNewsStatuses(currentToken){
		return __fetchEntity(currentToken, "serverNewsStatuses", EndpointsFactory.ENDPOINTS.DOM_GET_NEWS_STATUSES, WorkerResponseCode.DOM_GET_NEWS_STATUSES);
	}

	function _fetchTagLabels(currentLang, currentToken){
		return __fetchEntityLabels(currentLang, currentToken, "serverTagLabels", "dom_ta_tags", WorkerResponseCode.LABELS_GET_TAGS);
	}

	function _fetchTags(currentToken){
		return __fetchEntity(currentToken, "serverTags", EndpointsFactory.ENDPOINTS.DOM_GET_TAGS, WorkerResponseCode.DOM_GET_TAGS);
	}

	async function loadDomData(currentToken, currentLang, admin = false, guest = false){
		const appStore = useAppStore();
		appStore.showSpinner(true);
		try{
			if(guest){
				await Promise.all([]);
			}
			else{
				await Promise.all([]);
				if(admin){
					await Promise.all([_fetchCities(currentToken),
						_fetchPropertyCategories(currentToken), _fetchPropertiesCategoryLabels(currentLang, currentToken),
						_fetchPropertyAssigns(currentToken), _fetchPropertiesAssignLabels(currentLang, currentToken),
						_fetchCountries(currentToken), _fetchCountriesLabels(currentLang, currentToken),
						_fetchPropertiesTypologyLabels(currentLang, currentToken), _fetchPropertyAllTypologies(currentToken),
						_fetchPropertiesAppurtenances(currentToken),
						_fetchDirections(currentToken), _fetchDirectionLabels(currentLang, currentToken),
						_fetchMeasurements(currentToken), _fetchMeasurementLabels(currentLang, currentToken),
						_fetchHeatingTypes(currentToken), _fetchHeatingTypeLabels(currentLang, currentToken),
						_fetchHeatingPowers(currentToken), _fetchHeatingPowerLabels(currentLang, currentToken),
						_fetchPropertyConditions(currentToken), _fetchPropertiesConditionLabels(currentLang, currentToken),
						_fetchPropertyPurposes(currentToken), _fetchPropertiesPurposesLabels(currentLang, currentToken),
						_fetchPropertyRegulations(currentToken, currentLang), _fetchPropertiesRegulationLabels(currentLang, currentToken), _fetchPropertiesRegulationSublabelLabels(currentLang, currentToken),
						_fetchPropertyOutdoors(currentToken), _fetchPropertyOutdoorLabels(currentLang, currentToken),
						_fetchPropertyBenefits(currentToken), _fetchPropertyBenefitCategoriesLabels(currentLang, currentToken), _fetchPropertyBenefitLabels(currentLang, currentToken),
						_fetchActivities(currentToken), _fetchActivityLabels(currentLang, currentToken),
						_fetchRequestBenefitLabels(currentLang, currentToken), _fetchRequestBenefits(currentToken),
						_fetchTimeframeLabels(currentLang, currentToken), _fetchTimeframes(currentToken),
						_fetchTimeframesFilterLabels(currentLang, currentToken), _fetchTimeframesFilters(currentToken),
						_fetchNewsStatusesLabels(currentLang, currentToken), _fetchNewsStatuses(currentToken),
						_fetchTagLabels(currentLang, currentToken), _fetchTags(currentToken)
					]);
				}
				else{
					await Promise.all([
						_fetchCities(currentToken),
						_fetchPropertyPurposes(currentToken), _fetchPropertiesPurposesLabels(currentLang, currentToken),
						_fetchTimeframesFilterLabels(currentLang, currentToken), _fetchTimeframesFilters(currentToken),
						_fetchActivities(currentToken), _fetchActivityLabels(currentLang, currentToken)
					]);
				}
			}
			appStore.hideSpinner(true);
		}catch (e){
			appStore.showError();
		}
	}

	return {
		cities,
		propertyCategories,
		propertyAssigns,
		countries,
		propertyTypologies,
		propertyAllTypologies,
		directions,
		measurements,
		heatingTypes,
		heatingPowers,
		propertyConditions,
		propertyPurposes,
		propertyAppurtenances,
		propertyRegulations,
		propertyOutdoors,
		propertyBenefits,
		activities,
		requestBenefits,
		timeframes,
		timeframesFilters,
		newsStatuses,
		tags,

		fetchPropertyTypologies,
		loadDomData,
		reset,
		//questo è esposto solo perché così viene salvato in locale; ma non è pensato per essere usato
		serverData
	};
}, {
	persist: true
});

export const DOM_CITIES_FIELDS = {
	ID: 'id',
	NAME: 'name',
	PROVINCE_CODE: 'province_code',
	PROVINCE: 'province',
	PROGRESSIVE: 'progressive',
	CODE: 'city_code',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date',
	ENABLED: 'is_enabled'
};

const DOM_PROPERTY_CATEGORIES_FIELDS = {
	ID: 'id',
	PROPERTY_CATEGORY: 'property_category',
	LBL_KEY: 'label_key_property_category',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_PROPERTY_ASSIGNS_FIELDS = {
	ID: 'id',
	PROPERTY_ASSIGN: 'property_assign',
	LBL_KEY: 'label_key_property_assign',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_COUNTRIES_FIELDS = {
	ID: 'id',
	NAME: 'label_key_name',
	CITIZENSHIP: 'label_key_citizenship',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_PROPERTY_TYPOLOGIES_FIELDS = {
	ID: 'id',
	PROPERTY_TYPOLOGY: 'property_typology',
	LBL_KEY: 'label_key_property_typology',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date',
	PROPERTY_CATEGORY: 'property_category',
	PROPERTY_CATEGORY_ID: 'property_category_id'
};

const DOM_DIRECTIONS_FIELDS = {
	ID: 'id',
	DIRECTION: 'direction',
	LBL_KEY: 'label_key_direction',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_MEASUREMENTS_FIELDS = {
	ID: 'id',
	MEASUREMENT: 'measurement_unit',
	LBL_KEY: 'label_key_measurement_unit',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_HEATING_TYPES_FIELDS = {
	ID: 'id',
	HEATING_TYPE: 'heating_type',
	LBL_KEY: 'label_key_heating_type',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_HEATING_POWERS_FIELDS = {
	ID: 'id',
	HEATING_POWER: 'heating_power',
	LBL_KEY: 'label_key_heating_power',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_PROPERTY_CONDITIONS_FIELDS = {
	ID: 'id',
	PROPERTY_CONDITION: 'property_condition',
	LBL_KEY: 'label_key_property_condition',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_PROPERTY_PURPOSES_FIELDS = {
	ID: 'id',
	PROPERTY_PURPOSE: 'property_purpose',
	LBL_KEY: 'label_key_property_purpose',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_PROPERTY_REGULATIONS_FIELDS = {
	ID: 'id',
	PROPERTY_REGULATION: 'property_regulation',
	LBL_KEY: 'label_key_regulation',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date',
	SUB_LABEL: 'sublabel',
	SUB_LABEL_ID: 'sublabel_id',
	SUB_LABEL__CREATED_BY: 'created_by',
	SUB_LABEL__CREATION_DATE: 'creation_date',
	SUB_LABEL__LAST_UPDATE_BY: 'last_update_by',
	SUB_LABEL__LAST_UPDATE_DATE: 'last_update_date',
	SUB_LABEL__LABEL_KEY: 'label_key_regulation_sublabel',
	SUB_LABEL__SUBLABEL: 'regulation_sublabel',
	SUB_LABEL__ID: 'id',
	VALUE_TYPE: 'value_type',
	REQUIRED: 'is_required',
	ENUM_DOM_ROUTE: 'enum_dom_route',
};

const DOM_PROPERTY_OUTDOORS_FIELDS = {
	ID: 'id',
	PROPERTY_OUTDOOR: 'outdoor_type',
	LBL_KEY: 'label_key_outdoor_type',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_PROPERTY_BENEFIT_CATEGORY_FIELDS = {
	ID: 'id',
	PROPERTY_BENEFIT: 'benefit_category',
	LBL_KEY: 'label_key_benefit_category',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_PROPERTY_BENEFIT_FIELDS = {
	ID: 'id',
	PROPERTY_BENEFIT: 'benefit',
	LBL_KEY: 'label_key_benefit',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date',
	BENEFIT_CATEGORY: 'benefit_category',
	BENEFIT_CATEGORY_ID: 'benefit_category_id'
};

const DOM_ACTIVITIES_FIELDS = {
	ID: 'id',
	ACTIVITY: 'activity',
	LBL_KEY: 'label_key_activity_type',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date',
	ICON: 'mdi_icon'
};

const DOM_REQUEST_BENEFITS_FIELDS = {
	ID: 'id',
	PROPERTY_BENEFIT: 'benefit',
	LBL_KEY: 'label_key_benefit',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date',
	BENEFIT_CATEGORY: 'benefit_category',
	BENEFIT_CATEGORY_ID: 'benefit_category_id'
};

const DOM_TIMEFRAMES_FIELDS = {
	ID: 'id',
	TIMEFRAME: 'timeframe',
	LBL_KEY: 'label_key_timeframe',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_TIMEFRAMES_FILTERS_FIELDS = {
	ID: 'id',
	TIMEFRAME_FILTER: 'timeframe_filter',
	LBL_KEY: 'label_key_timeframe_filter',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_NEWS_STATUSES_FIELDS = {
	ID: 'id',
	NEWS_STATUS: 'news_status',
	LBL_KEY: 'label_key_news_status',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_TAGS_FIELDS = {
	ID: 'id',
	TAG: 'tag',
	LBL_KEY: 'label_key_tag',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date',
	COLOR: 'tag_color'
};