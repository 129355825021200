<template>
  <v-footer tag="footer" :class="{'mobile-footer':mobile}">
    <v-container>
      <v-row class="cop-info">
        <div>
          <v-icon icon="mdi-copyright"></v-icon>
          Smityx
        </div>
        <div v-if="!isProd" style="color:#00cccc;font-weight: bolder;">
          <br/>
          Environment: {{ currentMode.toUpperCase() }} - Versione: {{ version }}
        </div>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script setup>
import {useDisplay} from "vuetify";

const isProd = import.meta.env.PROD && import.meta.env.MODE === 'prod';
const currentMode = import.meta.env.MODE;
const version = __APP_VERSION__;
const {mobile} = useDisplay();
</script>

<style lang="less" scoped>
footer {
  padding-inline: 0;
  padding: 0;
  max-height: 250px;
  background: @secondary-color;
  color: @white;

  .v-container {
    max-width: 100%;
    padding: 0;
    padding-inline: 0;
  }
}

@space-border: 40px;

//riga in fondo nel footer
.cop-info {
  background: @primary-color;
  padding: 20px;
  padding-bottom: 45px;
  margin: 0;

  div {
    padding-left: (@space-border + 15px);
    padding-right: (@space-border + 15px);
    max-width: 1800px;
    margin: auto;
    width: 100%;
    color: @secondary-color;
    font-weight: 500;
  }
}

//MOBILE

//riga in fondo a tutto
.mobile-footer {
  .cop-info {
    div {
      padding: 0;
    }
  }
}
</style>