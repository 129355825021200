import {ref} from 'vue';
import {defineStore} from 'pinia';

export const useAppStore = defineStore('app', () => {
	const loading = ref(false);
	const domLoading = ref(false);
	const textLoading = ref(false);
	const saveOkFeedback = ref(false);
	const error = ref(false);
	const errorData = ref({});
	const expiredLogout = ref(false); //setto a true quando sto facendo logout causa sessione scaduta (errore 417) onde evitare spawn infiniti di errori
	const propertyPdf = ref(null);

	function showError(data = {}){
		errorData.value = data;
		error.value = true;
		loading.value = false;
	}

	function hideError(){
		error.value = false;
		errorData.value = {};
	}

	function showSpinner(dom = false){
		if(dom){
			domLoading.value = true;
			return;
		}
        loading.value = true;
    }

	function hideSpinner(dom = false, text = false){
		if(dom){
			domLoading.value = false;
			return;
		}
		if(text){
			textLoading.value = false;
			return;
		}
        loading.value = false;
    }

	function showSaveFeedback() {
		saveOkFeedback.value = true;
		setTimeout(()=>{
			saveOkFeedback.value = false;
		}, Number(import.meta.env.VITE_FEEDBACK_SAVE_TIMEOUT));
	}

	return {
        showSpinner,
        hideSpinner,
		error,
		errorData,
		showError,
        hideError,
        loading,
		domLoading,
		saveOkFeedback,
		showSaveFeedback,
		expiredLogout,
		propertyPdf
	};
});