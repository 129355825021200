export const USER_FIELDS = {
	email: 'email',
	email2: 'email2',
	password: 'password',
	password2: 'password2',
	id: 'id',
	name: 'firstname',
	surname: 'lastname',
	token: 'token',
	type: 'user_type_id',
	created_by: 'created_by',
	creation_date: 'creation_date',
	last_update_by: 'last_update_by',
	last_update_date: 'last_update_date',
	calendar: 'calendar_src',

	remember: 'is_remember',
	fullname: 'fullname' //solo per le ricerche
};

export const USER_TYPES = {
	ADMIN: 1,
	COORDINATOR: 2,
	AGENT: 3,
	CLIENT: 4
};

export const PROPERTY_STATUS = { //non ho voglia di prenderli dal dom...
	DRAFT: 1,
	ACTIVE: 2
};

export const CONTACT_FIELDS = {
	id: 'id',
	name: 'firstname',
	surname: 'lastname',
	last_contact: 'last_contact',
	type: 'type',
	city: 'city_id',
	phone: 'phone',
	address: 'address',
	notes: 'notes',
	internal_notes: 'private_notes',
	mailing_list: 'is_mailing_list',
	email: 'email',

	latest_request: 'latest_request_id'
};

export const ACTIVITY_FIELDS = {
	id: 'id',
	type: 'activity_type_id',
	date: 'creation_date',
	formatted_date: 'formatted_creation_date',
	property_id: 'property_id',
	contact_id: "contact_id",
	notes: 'notes',
	private_notes: 'private_notes',
	contact: "contact",
	target: 'target_value',
	activity_id: 'activity_id',

	time: 'creation_time'
};

export const REQUEST_FIELDS = {
	id: 'id',
	contact_obj: 'contact',
	property_category: 'category_id',
	date: 'creation_date',
	category: 'purpose_id',
	contact: 'contact_id',
	type: 'typologies',
	type__id: 'typology_id',
	bedrooms: 'room_no',
	bathrooms: 'bathroom_no',
	appurtenances: 'appliances',
	appurtenances__id: 'appliance_id',
	outside: 'outdoor_types',
	outside__id: 'outdoor_type_id',
	price_from: 'price_min',
	price_to: 'price_max',
	loan_consultancy_done: 'is_mortgage_counseling',
	loan: 'is_mortgage',
	sell: 'is_sale',
	notes: 'notes',
	places: 'cities',
	places__id: 'city_id',
	benefits: 'benefits',
	benefits__id: 'benefit_id',
	//solo per salvataggio; non arrivano in get
	SAVE_places: 'city_ids',
	SAVE_outside: 'outdoor_type_ids',
	SAVE_appurtenances: 'appliance_ids',
	SAVE_types: 'typology_ids',
	SAVE_benefits: 'benefit_ids',

	matching: 'is_matched'
};

export const TARGET_FIELDS = {
	id: 'id',
	target: 'target_value',
	type: 'activity_type_id',
	timeframe: 'timeframe_id',
	date: 'date_in_timeframe',
	user: 'user_id',
	date_start: 'start_date',
	date_end: 'end_date'
};

export const NOTIFICATION_FIELDS = {
	id: 'id',
	deadline: 'deadline',
	activity: 'activity'
};

export const PROPERTY_FIELDS = {
	id: 'id',
	city: 'city_id',
	owner: 'owner_contact_id',
	owner_obj: 'owner_contact',
	sub_type: 'typology_id',
	type: 'property_assign_id',
	price: 'price',
	rooms: 'room_no',
	sell_type: 'purpose_id',
	category: 'category_id',
	condominium: 'is_condo',
	address: 'address',
	country: 'country_id',
	zip: 'zip_code',
	reserved: 'is_private_negotiation',
	floors: "level_no",
	floor: "floor_no",
	bedrooms: "bedroom_no",
	bathrooms: "bathroom_no",
	appurtenances: "property_appliances",
	appurtenances__id: "appliance_id",
	exposure: "property_directions",
	exposure__id: "direction_id",
	heating_type: "heating_type_id",
	heating_powering: "heating_power_id",
	water_central: "is_water_centralized",
	status: "property_condition_id",
	restructured_year: "renovation_year",
	restructured_amount: "renovation_price",
	construction_year: "construction_year",
	overlook: "free_sides",
	floors_number: "total_floor_no",
	costs_amount: "condo_fees",
	land_registry_data: "land_register_data",
	land_registry_lot: "lot_reference",
	land_registry_block: "block_reference",
	land_registry_standard: "stair_reference",
	area_unit: 'measurement_unit_id',
	area_available: 'useful_surface',
	area_commercial: 'commercial_surface',
	list_details: 'property_surfaces',
	list_details__number: 'number',
	list_details__area: 'useful_surface',
	list_details__mq: 'criteria',
	list_details__id: 'id',
	list_details__unit: 'measurement_unit_id',
	outside: 'property_outdoors',
	outside__type: 'outdoor_type_id',
	outside__unit: 'measurement_unit_id',
	outside__area: 'surface',
	description_title: 'title',
	description_content: 'description',
	description_note: 'private_note',
	url: 'website',
	pictures: 'property_images',
	pictures__media: 'media',
	picture__is_cover: 'is_main_image',
	cover_picture: 'main_image_media_id',
	created_by: 'created_by',
	creation_date: 'creation_date',
	last_update_by: 'last_update_by',
	last_update_date: 'last_update_date',
	regulations: 'property_regulations',
	regulations__id: 'id',
	regulation__regulation_obj: 'regulation',
	regulations__regulation: 'regulation_id',
	regulations__date: 'document_date',
	regulations__expiry: 'document_expiry',
	regulations__type: 'type',
	benefits: 'property_benefits',
	benefits__id: 'benefit_id',
	assignment_start: 'assignment_start_date',
	assignment_end: 'assignment_end_date',
	assignment_end_document: 'assignment_cessation_media_id',
	notice_status: 'news_status_id',
	tag: 'tag',
	tag__id: 'id',
	tag__color: 'tag_color',
	property_status: 'status_id',
	//questi servono solo per i salvataggi; non arrivano dalle get
	SAVE_appurtenances: 'appliance_ids',
	SAVE_exposure: 'direction_ids',
	SAVE_outside: 'outdoors',
	SAVE_surfaces: 'surfaces',
	SAVE_benefits: 'benefit_ids',
	SAVE_regulations: 'regulations',
	SAVE_pictures: 'media_ids'
};

export const PROPERTY_REGULATION_VALUE_TYPES = {
	SELECT: 'int_value',
	TEXT: 'string_value',
	NUMBER: 'decimal_value'
};

export const MEDIA_FIELDS = {
	extension: 'extension',
	filename: 'filename',
	id: 'id'
};